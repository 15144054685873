import React from "react";
import { Form } from "react-bootstrap";
import { observer } from "mobx-react-lite";

const RadioButtonGroup = ({
  label,
  id,
  defaultValue,
  onChange,
  disabled,
}: {
  id: string;
  label?: string;
  defaultValue?: boolean;
  disabled?: boolean;
  onChange?: (checked: boolean) => Promise<void>;
}) => {
  return (
    <Form.Group key={id}>
      {label && <label htmlFor="type">{label}</label>}
      <Form.Check
        key={id}
        type="switch"
        id={id}
        defaultChecked={defaultValue}
        label=""
        disabled={disabled}
        onClick={(e) => onChange && onChange(e.currentTarget.checked)}
      />
    </Form.Group>
  );
};

export default observer(RadioButtonGroup);
